<template>
  <div>
    <!-- The header buttons -->
    <div class="d-flex justify-space-between">
      <!-- Show the go back button -->
      <v-btn
        text
        color="primary"
        @click="$router.push({ name: 'CampaignTrackingInfluencers' })"
      >
        <v-icon left>
          arrow_back
        </v-icon>

        Influencers
      </v-btn>

      <div class="d-flex">
        <v-btn
          v-if="overview.canUserWrite"

          text
          class="mr-3"
          color="primary"
          @click="shouldShowAddStoryDialog = true"
        >
          <v-icon left>
            add
          </v-icon>

          Add Story
        </v-btn>

        <!-- Show the posts button -->
        <v-btn
          text
          class="mr-3"
          color="primary"
          @click="$router.push({ name: 'CampaignTrackingPosts' })"
        >
          <v-icon left>
            collections
          </v-icon>

          Posts
        </v-btn>

        <!-- Show the analytics button -->
        <v-btn
          text
          color="primary"
          @click="$router.push({ name: 'CampaignTrackingAnalytics' })"
        >
          <v-icon left>
            analytics
          </v-icon>

          Analytics
        </v-btn>
      </div>
    </div>

    <!-- If we don't have any stories to show -->
    <div v-if="storiesCount === 0">
      <!-- Show the animation here -->
      <div style="max-width: 360px" class="mx-auto">
        <lottie-animation
          loop
          file="132293-social-media-post.json"
        />
      </div>

      <div class="text-center">
        There are no stories in this campaign yet.
      </div>

      <div class="text-center mt-6">
        <v-btn
          v-if="overview.canUserWrite"

          depressed
          color="primary"
          @click="shouldShowAddStoryDialog = true"
        >
          Add a Story
        </v-btn>
      </div>
    </div>

    <!-- Otherwise -->
    <div
      v-else
      class="pt-12"
    >
      <!-- Show a top row with summary -->
      <div class="d-flex flex-wrap align-center justify-space-between mb-4">
        <div class="d-flex">
          <!-- show the input for searching terms -->
          <div class="search-input search-input--contain">
            <profile-selector
              :overview="overview"
              label="Search Profile"
              type="search"
              dense
              flat
              solo
            />
          </div>
          <!-- Show the platform selector here -->
          <platform-selector
            module="campaignTracking"
            :overview="overview"
          />
        </div>

        <!-- Show the story stats here -->
        <div class="d-flex flex-wrap">
          <!-- For influencers -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <span class="mr-2">
                  {{ nFormatter(influencersCount) }}
                </span>

                Influencers
              </v-chip>
            </template>

            <span>
              {{ influencersCount }} influencers
            </span>
          </v-tooltip>

          <!-- For stories -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>blur_circular</v-icon>
                </v-avatar>

                {{ nFormatter(storiesCount) }}
              </v-chip>
            </template>

            <span>
              {{ storiesCount }} {{ storiesCount > 1 ? 'stories' : 'story' }}
            </span>
          </v-tooltip>

          <!-- For Reach -->
          <v-tooltip v-if="reachCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>trending_up</v-icon>
                </v-avatar>

                {{ nFormatter(reachCount) }}
              </v-chip>
            </template>

            <span>
              {{ reachCount }} Reach
            </span>
          </v-tooltip>

          <!-- For Impressions -->
          <v-tooltip v-if="impressionsCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>visibility</v-icon>
                </v-avatar>

                {{ nFormatter(impressionsCount) }}
              </v-chip>
            </template>

            <span>
              {{ impressionsCount }} Impressions
            </span>
          </v-tooltip>

          <!-- For Replies -->
          <v-tooltip v-if="repliesCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>chat_bubble</v-icon>
                </v-avatar>

                {{ nFormatter(repliesCount) }}
              </v-chip>
            </template>

            <span>
              {{ repliesCount }} Replies
            </span>
          </v-tooltip>

          <!-- For Reactions -->
          <v-tooltip v-if="reactionsCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>add_reaction</v-icon>
                </v-avatar>

                {{ nFormatter(reactionsCount) }}
              </v-chip>
            </template>

            <span>
              {{ reactionsCount }} Reactions
            </span>
          </v-tooltip>

          <!-- For Shares -->
          <v-tooltip v-if="sharesCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>share</v-icon>
                </v-avatar>

                {{ nFormatter(sharesCount) }}
              </v-chip>
            </template>

            <span>
              {{ sharesCount }} Shares
            </span>
          </v-tooltip>

          <!-- For Sticker Taps -->
          <v-tooltip v-if="stickerTapsCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>touch_app</v-icon>
                </v-avatar>

                {{ nFormatter(stickerTapsCount) }}
              </v-chip>
            </template>

            <span>
              {{ stickerTapsCount }} Sticker Taps
            </span>
          </v-tooltip>

          <!-- For Website Clicks -->
          <v-tooltip v-if="websiteClicksCount" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                color="secondary"
                class="ml-2"
                text-color="white"
                v-bind="attrs"
                v-on="on"
              >
                <v-avatar left>
                  <v-icon small>link</v-icon>
                </v-avatar>

                {{ nFormatter(websiteClicksCount) }}
              </v-chip>
            </template>

            <span>
              {{ websiteClicksCount }} Website Clicks
            </span>
          </v-tooltip>
        </div>
      </div>

      <!-- Show a divider in between -->
      <v-divider class="mb-3" />

      <!-- If we have  -->
      <template v-if="response && response.data">
        <div
          v-if="response.total === 0"
          class="text-center mt-4"
        >
          {{ selectedInfluencer ? 'No stories found for this influencer' : 'There are no stories, please try refreshing.' }}
        </div>

        <!-- Otherwise -->
        <div v-else>
          <div class="d-flex justify-space-between align-center">
            <!-- Option to short stories -->
            <div class="contain-select-width">
              <v-select
                v-if="selectedStoriesViewTab === 'grid'"
                v-model="sortBy"
                :items="sortOptions"
                label="Sort By"
                color="primary"
                hide-details
                outlined
                dense
              ></v-select>
            </div>

            <v-tabs
              right
              :value="selectedStoriesViewTab"
              @change="(v) => $emit('selectedStoriesViewTabChange', v)"
              background-color="transparent"
            >
              <v-tab href="#table">
                <v-icon left>
                  table_chart
                </v-icon>

                Table
              </v-tab>

              <v-tab href="#grid">
                <v-icon left>
                  grid_view
                </v-icon>

                Grid
              </v-tab>
            </v-tabs>
          </div>

          <v-tabs-items
            :value="selectedStoriesViewTab"
            @change="(v) => $emit('selectedStoriesViewTabChange', v)"
            class="mt-6 transparent"
          >
            <!-- Show the data table -->
            <v-tab-item value="table">
              <stories-table
                :key="refreshKey"
                :should-show-actions="true"
                :overview="overview"
              />
            </v-tab-item>

            <!-- Show the grid items -->
            <v-tab-item value="grid">
              <v-row>
                <v-col
                  v-for="item in response.data"
                  :key="'story-' + item.id"
                  cols="12"
                  sm="6"
                  lg="3"
                >
                  <!-- Use a common story component -->
                  <story :data="item" />
                </v-col>
              </v-row>

              <div
                id="intersect-detector"
                ref="intersectDetector"
                v-intersect="handleIntersect"
              ></div>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </template>
    </div>

    <!-- Show the add story dialog -->
    <add-story
      v-model="shouldShowAddStoryDialog"
      module="campaignTracking"
      :overview="overview"
    />
  </div>
</template>

<script>
// Import children components
const Story = () => import(/* webpackChunkName: "crm-story" */ "@/components/crm/Story.vue")
const AddStory = () => import(/* webpackChunkName: "crm-add-story" */ "@/components/crm/AddStory.vue")
const StoriesTable = () => import(/* webpackChunkName: "crm-stories-table" */ "@/components/crm/StoriesTable.vue")

const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")

const ProfileSelector = () => import(/* webpackChunkName: "crm-profile-selector" */ "@/components/crm/ProfileSelector.vue")
const PlatformSelector = () => import(/* webpackChunkName: "crm-platform-selector" */ "@/components/crm/PlatformSelector.vue")

// Export the SFC
export default {
  // Name of the component
  name: "CampaignTrackingStories",

  // Register children components
  components: {
    Story,
    AddStory,
    StoriesTable,

    ProfileChip,
    LottieAnimation,

    ProfileSelector,
    PlatformSelector
  },

  props: {
    overview: {
      type: Object,
      required: true
    },

    categories: {
      type: Array,
      required: true
    },

    selectedStoriesViewTab: {
      type: String,
      required: true,
    }
  },

  // Define local data variables
  data: () => ({
    // The refresh key for table
    refreshKey: Symbol(),

    // Sort filter values
    sortBy: "most_viewed",

    // The dropdown option values
    sortOptions: [
      {
        text: "Recent",
        value: "recent"
      },
      {
        text: "Oldest",
        value: "oldest"
      },
      {
        text: "Least Viewed",
        value: "least_viewed"
      },
      {
        text: "Most Viewed",
        value: "most_viewed"
      }
    ],

    // The key value pair for sort options
    sortMap: {
      recent: {
        sortBy: "posted_at",
        sortOrder: "desc"
      },

      oldest: {
        sortBy: "posted_at",
        sortOrder: "asc"
      },

      least_viewed: {
        sortBy: "reach",
        sortOrder: "asc"
      },

      most_viewed: {
        sortBy: "reach",
        sortOrder: "desc"
      },
    },

    // Whether or not to show the add story dialog
    shouldShowAddStoryDialog: false,

    // Whether or not a request is being made currently
    isMakingRequest: false
  }),

  // Define readonly computable variables
  computed: {
    /**
     * Get the total number of influencers here
     *
     * @returns {Number}
     */
    influencersCount() {
      return this.categories[0].story_accounts
    },

    /**
     * Get the total number of stories here
     *
     * @returns {Number}
     */
    storiesCount() {
      return this.categories[0].stories
    },

    /**
     * Get the number of total reach value
     *
     * @returns {Number}
     */
    reachCount() {
      return this.categories[0].reach
    },

    /**
     * Get the number of total impressions value
     *
     * @returns {Number}
     */
    impressionsCount() {
      return this.categories[0].impressions
    },

    /**
     * Get the number of total replies value
     *
     * @returns {Number}
     */
    repliesCount() {
      return this.categories[0].replies
    },

    /**
     * Get the number of total reactions value
     *
     * @returns {Number}
     */
    reactionsCount() {
      return this.categories[0].reactions
    },

    /**
     * Get the number of total shares value
     *
     * @returns {Number}
     */
    sharesCount() {
      return this.categories[0].story_shares
    },

    /**
     * Get the number of total sticker taps value
     *
     * @returns {Number}
     */
    stickerTapsCount() {
      return this.categories[0].sticker_taps
    },

    /**
     * Get the number of total website clicks value
     *
     * @returns {Number}
     */
    websiteClicksCount() {
      return this.categories[0].website_clicks
    },

    /**
     * Get the query object for the influencers
     *
     * @returns {Object}
     */
    query() {
      return this.$store.getters["campaignTracking/storiesGridQueryById"](this.overview.model.id)
    },

    /**
     * Get the current page number
     *
     * @returns {Number}
     */
    currentPage: {
      get() {
        return this.query.pagination.page
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updateStoriesGridQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              page: value
            }
          }
        })
      }
    },

    /**
     * Get the current per page value
     *
     * @returns {Number}
     */
    perPage: {
      get() {
        return this.query.pagination.perPage
      },

      set(value) {
        this.$store.dispatch("campaignTracking/updateStoriesGridQuery", {
          id: this.overview.model.id,
          query: {
            ...this.query,
            pagination: {
              ...this.query.pagination,
              perPage: value
            }
          }
        })
      }
    },

    /**
     * Get the response object for the influencers
     *
     * @returns {Object}
     */
    response() {
      return this.$store.getters["campaignTracking/storiesGridResponseById"](this.overview.model.id)
    },

    /**
     * Get the selected platform
     *
     * @returns {String}
     */
    selectedPlatform() {
      return this.$store.getters["campaignTracking/selectedPlatformById"](this.overview.model.id)
    },

    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
    selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Set watchers for local data
  watch: {
    /**
     * If the selected platform changes
     *
     * @returns {void}
     */
    selectedPlatform: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchGridStories()
      }
    },

    /**
     * If the selected influencer changes
     *
     * @returns {void}
     */
    selectedInfluencer: {
      handler() {
        // Ask to fetch the data from the server again
        this.fetchGridStories()
      }
    },

    /**
     * If the sort by value changes
     *
     * @returns {void}
     */
    sortBy() {
      // Reset the pagination
      this.currentPage = 1

      // Call the search function
      this.fetchGridStories()
    },
  },

  // Define local method functions
  methods: {
    /**
     * Fetch the grid stories
     *
     * @returns {void}
     */
    async fetchGridStories() {
      // If a request is being made
      if (this.isMakingRequest) return false

      // Show a loader
      const loaderId = Symbol("CampaignTracking/fetchStoriesGrid")
      this.$store.dispatch("loaders/add", loaderId)
      this.isMakingRequest = true

      // Get the influencer object from API
      try {
        // Define the query params
        const queryParams = new window.URLSearchParams(
          Object.entries({
            page: this.query.pagination.page,
            per_page: this.query.pagination.perPage,

            platform: this.selectedPlatform,
            influencer: this.selectedInfluencer ? this.selectedInfluencer.id : null,

            ...this.sortMap[this.sortBy]
          }).filter(([key, value]) => Boolean(value))
        )

        // Use helper function
        const response = await axios(`/api/campaign-tracking/${this.overview.model.id}/stories?${queryParams}`)

        // Update the response object
        this.$store.dispatch("campaignTracking/updateStoriesGridResponse", {
          id: this.overview.model.id,
          response: response.data
        })
      }
      // Catch any error
      catch (error) {
        // Show a toast
        this.$store.dispatch("toasts/add", { text: "Failed to fetch stories!" })

        // Log it
        logger({ type: "CampaignTracking/fetchGridStories Error", error })
      }
      // Nonetheless
      finally {
        // Hide the loader
        this.$store.dispatch("loaders/remove", loaderId)
        this.isMakingRequest = false
      }
    },

    /**
     * Whether or not the user has scrolled past the intersection limit
     *
     * @param {Object} entries
     * @param {Object} observer
     * @param {Boolean} isIntersecting
     */
    handleIntersect(entries, observer, isIntersecting) {
      // Stop execution if user didn't scroll down
      if (!isIntersecting) return false

      // If a request is being made, don't continue
      if (this.isMakingRequest) return false

      // If there's no data possible, don't continue
      if (this.response.data.length >= this.response.total) return false

      // Otherwise, call the search function
      this.currentPage++
      this.fetchGridStories()
    },

    /**
     * Handle the refresh event
     *
     * @returns {void}
     */
    handleRefreshEvent() {
      // Update the refresh key
      this.refreshKey = Symbol()

      // Use helper function
      this.fetchGridStories()

      // Call to refresh overview
      this.$emit("refreshData")
    }
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  async created() {
    // Switch the currently selected platform to "instagram"
    this.$store.dispatch("campaignTracking/updateSelectedPlatform", {
      id: this.overview.model.id,
      value: "instagram"
    })

    // If the query value is not set
    if (!this.query) {
      // Set the default query value
      this.$store.dispatch("campaignTracking/updateStoriesGridQuery", {
        id: this.overview.model.id,
        query: {
          pagination: {
            page: 1,
            perPage: 4
          },

          sortBy: "most_viewed",
        }
      })
    }

    // If there's a selected influencer
    if (this.selectedInfluencer) {
      // Switch to grid view
      this.$emit("selectedStoriesViewTabChange", "grid")
    }

    // Fetch the grid stories
    this.fetchGridStories()

    // Add event listener for refresh
    window.addEventListener("campaignTracking:refreshStories", this.handleRefreshEvent)
  },

  /**
   * As soon as the component is to be destroyed
   *
   * @returns {void}
   */
  beforeDestroy() {
    // Remove event listener for refresh
    window.removeEventListener("campaignTracking:refreshStories", this.handleRefreshEvent)
  }
}
</script>

<style lang="stylus" scoped>
.contain-select-width
  width 15em

  &--large
    width 20em
</style>
